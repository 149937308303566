import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Input, Typography, Checkbox, notification, Modal } from 'antd';

import { UserCredentialsContext } from './App';
import { handleUserRequest } from './utils';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { checkAndSaveDomainStatus } from './utils';
import { LoadingOutlined } from '@ant-design/icons';
import YouTubeBackground from './YouTubeBackground'; // Import at the top
import './LoginPage.css';

function LoginPage() {
  const [emailLogin, setEmailLogin] = useState('');
  const [passwordLogin, setPasswordLogin] = useState('');
  const [emailRegister, setEmailRegister] = useState('');
  const [passwordRegister, setPasswordRegister] = useState('');
  const [showLogin, setShowLogin] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const setUserCredentials = useContext(UserCredentialsContext);
  const [agreedToLicense, setAgreedToLicense] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [seatextId, setSeatextId] = useState('');
  
  // State for mobile modal confirmation flow
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [pendingAction, setPendingAction] = useState(null); // 'login' or 'register'
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  // Update isMobile on resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let parameterReceived = false;
  
    // Check for seatextId in URL
    const params = new URLSearchParams(location.search);
    const seatextIdFromUrl = params.get('seatextId');
    if (seatextIdFromUrl) {
      setSeatextId(seatextIdFromUrl);
      parameterReceived = true;
    }
  
    const hash = location.hash.slice(1); // Remove the '#' at the start
    if (hash) {
      try {
        const decodedHash = atob(hash);
        const params = new URLSearchParams(decodedHash);
        const login = params.get('login');
        const password = params.get('password');
        if (login && password) {
          handleAutoLogin(login, password);
          parameterReceived = true;
        }
      } catch (error) {
        console.error('Error decoding hash:', error);
      }
    } else if (!Cookies.get('email') || !Cookies.get('password')) {
      setShowLogin(false);
    }
  
    // Set the wordpress cookie if any parameter was received
    if (parameterReceived) {
      Cookies.set('wordpress', '1', { expires: 7 }); // Cookie expires in 7 days
    }
  }, [location]);

  async function handleAutoLogin(email, password) {
    setIsLoading(true);
    try {
      const cleanedEmail = cleanEmail(email);
      const success = await handleUserRequest(
        true,
        cleanedEmail,
        password,
        setUserCredentials,
        navigate,
        handleError,
        seatextId
      );
      if (success) {
        await checkAndSaveDomainStatus();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleError = (error) => {
    setIsLoading(false);
  };

  useEffect(() => {
    // If email and password cookies do not exist, show the registration screen
    if (!Cookies.get('email') || !Cookies.get('password')) {
      setShowLogin(false);
    }
  }, []);

  const showNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      duration: 20, // seconds
    });
  };

  // Remove unwanted characters from an email address
  function cleanEmail(email) {
    email = email.trim();
    return email.replace(/[^\w!#$%&'*+/=?^{|}~@.-]/g, '');
  }

  // When a user submits a form, check for mobile and show modal if needed
  function triggerSubmission(actionType) {
    if (isMobile && !Cookies.get('mobileModalConfirmed')) {
      setPendingAction(actionType);
      setShowMobileModal(true);
      return;
    }
    // Otherwise, proceed immediately
    if (actionType === 'login') {
      handleLoginSubmit();
    } else if (actionType === 'register') {
      handleRegisterSubmit();
    }
  }

  async function handleLoginSubmit() {
    setIsLoading(true);
    try {
      const cleanedEmail = cleanEmail(emailLogin);
      const result = await handleUserRequest(
        true,
        cleanedEmail,
        passwordLogin,
        setUserCredentials,
        navigate,
        handleError,
        seatextId
      );
      
      if (result.status === "password_reminder_sent") {
        showNotification(
          'info',
          'Password Reminder Sent',
          'A password reminder has been sent to your email. Please check your inbox.'
        );
        setShowLogin(true); // Ensure the login form is shown
      } else if (result.success) {
        // Login successful, navigation is handled in handleUserRequest
      } else {
        let errorMessage = 'An error occurred during login. Please try again.';
        if (typeof result.error === 'string') {
          if (result.error.startsWith("{")) {
            try {
              const parsedError = JSON.parse(result.error);
              errorMessage = parsedError.message || errorMessage;
            } catch (e) {
              console.warn("Failed to parse error message:", e);
            }
          } else {
            errorMessage = result.error;
          }
        } else if (typeof result.error === 'object' && result.error.message) {
          errorMessage = result.error.message;
        }
        showNotification(
          'error',
          'Login Failed',
          errorMessage
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRegisterSubmit() {
    setIsLoading(true);
    try {
      const cleanedEmail = cleanEmail(emailRegister);
      const registerResult = await handleUserRequest(
        false,
        cleanedEmail,
        passwordRegister,
        setUserCredentials,
        navigate,
        handleError,
        seatextId
      );
      
      if (registerResult.success) {
        // Registration successful, navigation is handled in handleUserRequest
      } else {
        // If registration fails, attempt to log in
        const loginResult = await handleUserRequest(
          true,
          cleanedEmail,
          passwordRegister,
          setUserCredentials,
          navigate,
          handleError,
          seatextId
        );
        
        if (loginResult.success) {
          showNotification(
            'success',
            'Login Successful',
            'You already had an account. You have been logged in.'
          );
        } else if (loginResult.status === "password_reminder_sent") {
          showNotification(
            'info',
            'Password Reminder Sent',
            'A password reminder has been sent to your email. Please check your inbox.'
          );
          setShowLogin(true); // Switch to login form
        } else {
          showNotification(
            'error',
            'Account Creation Failed',
            'An account with this email already exists. Please try logging in.'
          );
          setShowLogin(true); // Switch to login form
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  // Called when the user confirms they wish to continue on mobile
  const handleConfirmMobile = () => {
    Cookies.set('mobileModalConfirmed', 'true', { expires: 365 });
    setShowMobileModal(false);
    if (pendingAction === 'login') {
      handleLoginSubmit();
    } else if (pendingAction === 'register') {
      handleRegisterSubmit();
    }
    setPendingAction(null);
  };

  return (
    <div className="login-page-container">
      {/* Mobile Modal – now shown only when the user submits on mobile */}
      <Modal
        open={showMobileModal}
        footer={null}
        closable={false}
        width="90vw"
        style={{ 
          top: 20,
          zIndex: 1000,
          margin: '0 auto'
        }}
        bodyStyle={{ 
          padding: '20px',
          background: '#f8f9fa',
          boxSizing: 'border-box'
        }}
      >
        <div style={{ maxWidth: '500px', margin: '0 auto', width: '100%' }}>
          <div style={{ marginBottom: '24px', fontSize: '48px' }}>🚧</div>
          
          <Typography.Title level={3} style={{ marginBottom: '16px', color: '#2d3436' }}>
            Optimized for Larger Screens
          </Typography.Title>
          
          <Typography.Paragraph style={{ 
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#636e72',
            marginBottom: '32px'
          }}>
            We apologize for the inconvenience! Our application performs best on desktops, laptops, tablets, and MacBooks.
            If you must use your mobile device, please confirm to proceed.
          </Typography.Paragraph>

          {/* Option 1: Continue on Mobile */}
          <div style={{ marginTop: '24px' }}>
            <Button type="default" onClick={handleConfirmMobile}>
              I’d like to continue on my mobile device
            </Button>
          </div>

          {/* Option 2: Use the Desktop Version – only render for mobile */}
        

          <Typography.Text type="secondary" style={{ fontSize: '14px', marginTop: '16px' }}>
            Mobile version coming very soon! ⏳<br/>
            Follow us for updates @seatext
          </Typography.Text>
        </div>
      </Modal>

      <div className="external-content-container">
  <YouTubeBackground />
</div>
  
<div className="auth-container" data-seatextdont style={{ width: '50%', margin: '0 auto' }}>
        <div className={`auth-section ${showLogin ? '' : 'hidden'}`}>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>Sign In</Typography.Title>
          <Form onFinish={() => triggerSubmission('login')}>
            <Form.Item
              name="emailLogin"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                id="email-login"
                placeholder="Email Address"
                onChange={(e) => setEmailLogin(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="passwordLogin"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                id="password-login"
                placeholder="Password"
                onChange={(e) => setPasswordLogin(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Button htmlType="submit" block style={{
              backgroundColor: '#000',
              borderColor: '#000',
              color: '#fff',
              fontWeight: 'bold',
              height: '50px',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} disabled={isLoading}>
              {isLoading ? (
                <>
                  <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />
                  <span style={{ marginLeft: 8 }}>Signing In...</span>
                </>
              ) : (
                "Sign In"
              )}
            </Button>
          </Form>
          <Button type="link" onClick={() => setShowLogin(false)}>
            Don't have an account? 
          </Button>
        </div>

  
        <div className={`auth-section ${showLogin ? 'hidden' : ''}`} data-seatextdont>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>Create account</Typography.Title>
          <div className="benefits-section register-benefits">
            <ul>
              <li>Please provide a valid corporate email, as it is important for the security of your website</li>
            </ul>
          </div>
          <Form onFinish={() => triggerSubmission('register')}>
            <Form.Item
              name="emailRegister"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                id="email-register"
                placeholder="Email Address"
                onChange={(e) => setEmailRegister(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="passwordRegister"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                id="password-register"
                placeholder="Password"
                onChange={(e) => setPasswordRegister(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" block style={{
              backgroundColor: '#000',
              borderColor: '#000',
              color: '#fff',
              fontWeight: 'bold',
              height: '50px',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} disabled={isLoading || !agreedToLicense}>
              {isLoading ? (
                <>
                  <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />
                  <span style={{ marginLeft: 8 }}>Registering...</span>
                </>
              ) : (
                "Continue"
              )}
            </Button>
            <Form.Item name="agreement" valuePropName="checked" initialValue={true}>
              <Checkbox onChange={(e) => setAgreedToLicense(e.target.checked)} checked={agreedToLicense}>
                I agree to the{" "}
                <Link to="/license-agreement" data-seatextdont>I agree to the SEATEXT AI license agreement</Link>

              </Checkbox>
            </Form.Item>
          </Form>
          <Button type="link" onClick={() => setShowLogin(true)}>
  Already have an account? Sign in here.
</Button>
        </div>

        {/* Bonus credits section */}
<div style={{ 
    width: '80%',
    margin: '16px auto 0',
    textAlign: 'center',
    padding: '0 15px'
}}>
    <Typography.Text type="secondary">
        Want to get up to $3000 as bonus credits?{' '}
        <a 
            href="javascript:void(0)" 
            style={{ 
                fontWeight: 500,
                color: '#1890ff',
                cursor: 'pointer'
            }}
            onClick={() => {
                window.open(
                    "https://calendly.com/sgluhov/seatext-com",
                    '_blank',
                    'noopener,noreferrer'
                );
                return false;
            }}
        >
            Ask us for personalized demo
        </a>
        {' '}and if you qualify - you will be credited up to $3000.
    </Typography.Text>
</div>
      </div>




    </div>
  );
}

export default LoginPage;
