import React, { useState, useEffect } from 'react';
import { Typography, Card, Button, Input, Form, Collapse, Avatar, Switch, Tooltip, message, Modal, Upload, Table, Spin } from 'antd';
import { PlusOutlined, UserOutlined, InfoCircleOutlined, CopyOutlined, EditOutlined, DeleteOutlined, UploadOutlined, DownloadOutlined, LinkOutlined, RobotOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { fetchSettingsFromServer, loadKeywordProfilesFromServer, sendKeywordProfilesToServer, getDomainFromCookies, deleteKeywordData } from './utils';
import AIPersonalizationFeatures from './aipersonalisationfeatures';
import PersonalizedVariantEditor from './PersonalizedVariantEditor';
import LottieAnimation from './LottieAnimation';
import { sendKeywordsTableToServer} from './utils';
import VideoIntro from './VideoIntro';
import VideoPlayer from './VideoPlayer';


const { Title } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;


const normalizeColumnName = (columnName) => {
  if (!columnName) return '';
  // Convert to lowercase and remove special characters
  const normalized = columnName.toLowerCase()
    .replace(/[^a-z0-9]/g, '');

  // Define variations for each expected column
  const columnMappings = {
    // Keyword variations
    'keyword': ['keyword', 'keywords', 'searchterm', 'searchterms', 'search', 'term', 'terms', 'keyphrase', 'keyphrases', 'query'],
    
    // Base URL variations
    'baseurl': ['baseurl', 'url', 'link', 'webpage', 'website', 'domain', 'page', 'address', 'destination', 'target'],
    
    // AI Instructions variations
    'aiinstructions': ['aiinstructions', 'instructions', 'aiprompt', 'prompt', 'description', 'optimization', 'aitext', 'content', 'rules', 'guidelines']
  };

  // Find matching column type
  for (const [standardName, variations] of Object.entries(columnMappings)) {
    if (variations.includes(normalized)) {
      return standardName;
    }
  }

  return normalized;
};


const normalizeUrl = (url) => {
  if (!url) return '';
  
  // Ensure URL starts with http:// or https://
  let normalizedUrl = url.trim();
  if (!normalizedUrl.match(/^https?:\/\//i)) {
    normalizedUrl = `https://${normalizedUrl}`;
  }

  try {
    // Parse the URL to preserve all components
    const parsedUrl = new URL(normalizedUrl);
    
    // Remove trailing slash only if there's no path (other than /)
    if (parsedUrl.pathname === '/') {
      parsedUrl.pathname = '';
    }
    
    // Remove any existing seatext parameter before returning
    parsedUrl.searchParams.delete('seatext');
    
    return parsedUrl.toString();
  } catch (error) {
    console.error('Invalid URL:', normalizedUrl);
    return normalizedUrl;
  }
};


const isValidUrl = (url) => {
  try {
  new URL(url.startsWith('http') ? url : 'https://' + url);
  return true;
  } catch (error) {
  return false;
  }
  };


  const removeSeatextFromUrl = (url) => {
    try {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.delete('seatext');
    return parsedUrl.toString();
    } catch (error) {
    return url.split('?')[0];
    }
    };

      // Helper function to generate a personalized URL
      const generatePersonalizedUrl = (baseUrl, personalizationId) => {
        if (!baseUrl || !personalizationId) return baseUrl;
        
        try {
          // Normalize and parse the base URL
          const normalizedBaseUrl = normalizeUrl(baseUrl);
          const url = new URL(normalizedBaseUrl);
          
          // Remove any existing seatext parameter
          url.searchParams.delete('seatext');
          
          // Add the new seatext parameter
          url.searchParams.set('seatext', personalizationId);
          
          return url.toString();
        } catch (error) {
          console.error('Invalid base URL:', baseUrl);
          // Fallback handling while preserving the path
          const cleanBaseUrl = normalizeUrl(baseUrl);
          const separator = cleanBaseUrl.includes('?') ? '&' : '?';
          return `${cleanBaseUrl}${separator}seatext=${personalizationId}`;
        }
      };



function AILandingPageOptimization(props) {
  const [keywords, setKeywords] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [domain, setDomain] = useState('');
  const [editorVisible, setEditorVisible] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [keywordToDelete, setKeywordToDelete] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasTargetUrl, setHasTargetUrl] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentKeyword, setCurrentKeyword] = useState(null);
  const [addKeywordModalVisible, setAddKeywordModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [urlInputValue, setUrlInputValue] = useState(`https://${getDomainFromCookies()}/`);
  const [isLoadingVariants, setIsLoadingVariants] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [countdown, setCountdown] = useState(0);



  
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    fetchSettingsFromServer(props.context?.url)
      .catch(error => console.error('Failed to fetch settings:', error));
  
    loadKeywordProfilesFromServer()
      .then(response => {
        console.log('Response from server:', response);
        
        if (response.success) {
          // Handle multiple levels of nesting
          const extractData = (responseObj) => {
            if (Array.isArray(responseObj)) return responseObj;
            if (responseObj.data) return extractData(responseObj.data);
            return [];
          };
  
          // Get the actual data array regardless of nesting level
          const keywordsData = extractData(response.data);
          
          // Transform the data to match the expected structure
          const transformedKeywords = keywordsData.map(item => ({
            id: item._id || item.personalizationId || Date.now(),
            keyword: item.keyword || '',
            autoUrl: item.autoUrl || `https://${getDomainFromCookies()}/`,
            personalizationId: item.personalizationId || generatePersonalizationId(item.keyword),
            personalizationData: item.aiInstructions || '',  // Map aiInstructions to personalizationData
            lastUpdated: item.lastUpdated || new Date().toISOString(),
            focusUrl: item.autoUrl || `https://${getDomainFromCookies()}/`  // Use autoUrl as focusUrl
          }));
    
          console.log('Transformed keywords:', transformedKeywords);
          setKeywords(transformedKeywords);
    
          // Update target URL state
          const initialHasTargetUrl = {};
          transformedKeywords.forEach(keyword => {
            initialHasTargetUrl[keyword.id] = !!keyword.autoUrl;
          });
          setHasTargetUrl(initialHasTargetUrl);
        } else {
          console.error('Failed to load keywords:', response.error);
          message.error('Failed to load keywords: ' + (response.error || 'Unknown error'));
        }
      })
      .catch(error => {
        console.error('Failed to load keywords:', error);
        message.error('Failed to load keywords. Please try again.');
      });
  
    const domainFromCookies = getDomainFromCookies();
    if (domainFromCookies) {
      setDomain(domainFromCookies);
    }
  }, [props.context?.url]);

  // Helper function to generate a personalization ID
  const generatePersonalizationId = (keyword) => {
    if (keyword?.trim()) {
      return keyword.toLowerCase().trim().replace(/[^a-z0-9]/g, '');
    }
    return generateRandomIdentifier();
  };

  // Improved random identifier generator
  const generateRandomIdentifier = () => {
    return `kw${Math.random().toString(36).substring(2, 8)}`;
  };





  const batchProcessKeywords = async () => {
    if (isProcessing) return;
    
    // Set processing state and countdown at the start
    setIsProcessing(true);
    setCountdown(60);
    
    const serverProfiles = {
      profiles: keywords.map(k => ({
        "Keyword": k.keyword,
        "Base URL": k.autoUrl || k.baseUrl,
        "AI Instructions": k.personalizationData,
        "Personalization ID": k.personalizationId
      }))
    };
  
    try {
      const result = await sendKeywordsTableToServer(serverProfiles);
      if (result.success) {
        message.success(`Successfully processed ${keywords.length} keywords`);
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      message.error(`Failed to process keywords: ${error.message}`);
    }
  
    // Keep the button disabled for the full minute regardless of API success/failure
    setTimeout(() => {
      setIsProcessing(false);
      setCountdown(0); // Reset countdown when done
    }, 60000);
  };



  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text) => (
        <Tooltip title={text ?? ''}>
          <span>{text?.slice(0, 40) ?? ''}</span>
        </Tooltip>
      ),
      width: '20%',
    },
    {
      title: 'AI Instruction',
      dataIndex: 'personalizationData',
      key: 'personalizationData',
      width: '40%',
      render: (text) => (
        <div style={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
          lineHeight: '1.5em',
          maxHeight: '6em',
          whiteSpace: 'pre-line',
          wordWrap: 'break-word'
        }}>
          {text ?? ''}
        </div>
      ),
    },
    {
      title: 'Personalized URL',
      dataIndex: 'autoUrl', // Changed from focusUrl
      key: 'autoUrl',
      render: (text, record) => {
        const fullUrl = generatePersonalizedUrl(text, record.personalizationId);
        
        return (
          <Tooltip title={fullUrl}>
            <a 
              href={fullUrl} 
              target="_blank"
              rel="noopener noreferrer"
            >
              {text.replace(/^https?:\/\//, '').slice(0, 30)}...?seatext={record.personalizationId}
            </a>
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '90px' }}>
          <Tooltip title="Edit keyword information">
            <Button 
              icon={<EditOutlined />} 
              type="text" 
              style={{ background: 'transparent', border: 'none', padding: '4px' }}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete keyword and associated AI texts">
            <Button 
              icon={<DeleteOutlined />} 
              type="text" 
              style={{ background: 'transparent', border: 'none', padding: '4px' }}
              onClick={() => showDeleteConfirm(record)}
            />
          </Tooltip>
          <Tooltip title="Edit AI-generated text variants">
            <Button 
              icon={<RobotOutlined />} 
              type="text" 
              style={{ background: 'transparent', border: 'none', padding: '4px' }}
              onClick={() => openEditor(record)}
            />
          </Tooltip>
        </div>
      ),
      width: '120px',
    },
  ];

  const handleCSVUpload = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.data && results.data.length > 0) {
          // Normalize column headers
          const headerMapping = {};
          const originalHeaders = results.meta.fields || [];
          
          originalHeaders.forEach(header => {
            const normalizedHeader = normalizeColumnName(header);
            if (['keyword', 'baseurl', 'aiinstructions'].includes(normalizedHeader)) {
              headerMapping[header] = normalizedHeader;
            }
          });
  
          // Function to get value from row using normalized headers
          const getValue = (row, targetColumn) => {
            const matchingHeader = Object.entries(headerMapping)
              .find(([_, normalized]) => normalized === targetColumn)?.[0];
            return matchingHeader ? row[matchingHeader]?.trim() : '';
          };
  
          const newKeywords = results.data.map((row, index) => {
            // Get values using flexible column names
            const keyword = getValue(row, 'keyword');
            const baseUrl = getValue(row, 'baseurl') || `https://${domain}/`;
            const aiInstructions = getValue(row, 'aiinstructions');
  
            // Skip rows without required data
            if (!keyword) {
              console.warn('Skipping row without keyword:', row);
              return null;
            }
  
            // Normalize the URL before storing
            const normalizedUrl = normalizeUrl(baseUrl);
  
            return {
              id: Date.now() + index,
              keyword,
              autoUrl: normalizedUrl,
              personalizationId: generatePersonalizationId(keyword),
              personalizationData: aiInstructions,
            };
          }).filter(Boolean); // Remove null entries
  
          if (newKeywords.length > 0) {
            setKeywords(prevKeywords => {
              const updatedKeywords = [...prevKeywords];
              newKeywords.forEach(newKeyword => {
                const existingIndex = updatedKeywords.findIndex(k => 
                  k.personalizationId === newKeyword.personalizationId
                );
                if (existingIndex !== -1) {
                  updatedKeywords[existingIndex] = { ...updatedKeywords[existingIndex], ...newKeyword };
                } else {
                  updatedKeywords.push(newKeyword);
                }
              });
              message.success(`Successfully processed ${newKeywords.length} keywords`);
              return updatedKeywords;
            });
          } else {
            message.warning('No valid keywords found in the CSV file');
          }
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        message.error('Failed to parse CSV file. Please check the format.');
      }
    });
  };

  const KeywordForm = ({ initialValues, onFinish, form }) => {
    const [baseUrl, setBaseUrl] = useState(initialValues?.baseUrl || '');

    return (
      <Card style={{ 
        width: '1000px',
        margin: '0 auto',
        boxShadow: 'none',
        border: 'none'
      }}>
        <Form
          form={form}
          initialValues={{...initialValues, focusUrl: baseUrl}}
          onFinish={(values) => {
            const keyword = values.keyword?.trim();
            const personalizationId = generatePersonalizationId(keyword);
            const normalizedUrl = normalizeUrl(values.focusUrl);
            
            onFinish({
              ...values,
              keyword: keyword || '',
              autoUrl: normalizedUrl,
              personalizationId
            });
          }}
          layout="vertical"
        >
          <Form.Item 
            name="keyword" 
            label="Keyword"
            rules={[{ required: true, message: 'Please input the keyword!' }]}
          >
            <Input maxLength={255} />
          </Form.Item>
          
          <Form.Item
  name="focusUrl"
  label="URL of page you want to optimize"
  rules={[
    { required: true, message: 'Please input the URL!' },
    {
      validator: (_, value) => {
        if (!isValidUrl(value)) {
          return Promise.reject('Please enter a valid website address');
        }
        return Promise.resolve();
      },
    },
  ]}
>
  <Input 
    maxLength={2048}
    placeholder="example.com"
    onChange={(e) => {
      const normalizedValue = normalizeUrl(e.target.value);
      form.setFieldsValue({ focusUrl: normalizedValue });
    }}
  />
</Form.Item>

          <Form.Item 
            name="personalizationData" 
            label={
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>AI Instructions</span>
                  <Tooltip title="Enter detailed instructions for AI about how to optimize the page content for this keyword">
                    <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                  </Tooltip>
                </div>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => 
                    prevValues?.personalizationData !== currentValues?.personalizationData
                  }
                >
                  {({ getFieldValue }) => {
                    const currentLength = getFieldValue('personalizationData')?.length || 0;
                    return (
                      <span style={{ 
                        fontSize: '12px', 
                        color: currentLength > 900000 ? '#ff4d4f' : '#8c8c8c' 
                      }}>
                        {currentLength.toLocaleString()} / 1,000,000
                      </span>
                    );
                  }}
                </Form.Item>
              </div>
            }
            rules={[
              { required: true, message: 'Please input AI instructions!' },
              { validator: validatePersonalizationData }
            ]}
          >
            <Input.TextArea
              style={{
                height: '400px',
                fontSize: '14px',
                lineHeight: '1.6'
              }}
              showCount={{
                formatter: ({ count }) => `${count.toLocaleString()} characters`
              }}
              placeholder="Enter detailed instructions for the AI about how to optimize the page content for this keyword. We recommend:

- Target keyword and related terms
- Key messages to emphasize
- Tone and style preferences
- Specific sections to modify
- Content elements to maintain
- SEO optimization goals
- Target audience characteristics
- Direct instructions for AI on how to rewrite the content"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {initialValues?.id ? 'Save Changes' : 'Add Keyword'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  };

  //// First, let's update the validatePersonalizationData function to allow empty values
  const validatePersonalizationData = (rule, value) => {
    // Allow empty values
    if (!value || value.trim() === '') {
      return Promise.resolve();
    }
    
    const maxLength = 1000000;
    
    if (value.length > maxLength) {
      return Promise.reject(`AI instructions exceed maximum length of ${maxLength} characters`);
    }
    
    return Promise.resolve();
  };

  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();

  const modalStyle = {
    minWidth: '1060px',
    top: '20px'
  };

  const handleCSVExport = () => {
    const escapeCSVField = (field) => {
      if (field === null || field === undefined) return ''; 

      const stringField = String(field);
      const shouldEscape = stringField.includes('"') || stringField.includes(',') || stringField.includes('\n') || stringField.includes('\r');

      if (shouldEscape) {
        return `"${stringField.replace(/"/g, '""')}"`;
      }
      return stringField;
    };

    const headers = [
      'Keyword', 'Personalized URL', 'Personalization ID', 'AI Instructions'
    ];

    const csvRows = [
      headers.map(escapeCSVField).join(','),
      ...keywords.map((k) => {
        const fullPersonalizedUrl = generatePersonalizedUrl(k.autoUrl, k.personalizationId);
        return [
          k.keyword,
          fullPersonalizedUrl, // For display
          k.personalizationId,
          k.personalizationData,
        ].map(escapeCSVField).join(',');
      })
].join('\r\n');

try {
const blob = new Blob([`\uFEFF${csvRows}`], { type: 'text/csv;charset=utf-8;' });
const link = document.createElement('a');
link.href = URL.createObjectURL(blob);
link.download = 'keyword-optimizations.csv';
link.style.display = 'none';

document.body.appendChild(link);
link.click();

setTimeout(() => {
URL.revokeObjectURL(link.href);
document.body.removeChild(link);
}, 500);

} catch (error) {
console.error("Error generating or downloading CSV:", error);
message.error("An error occurred while downloading the CSV. Please try again.");
}
};





const ensureSeatextInUrl = (url, personalizationId) => {
if (!url) return url;
const baseUrl = removeSeatextFromUrl(url);
const separator = baseUrl.includes('?') ? '&' : '?';
return `${baseUrl}${separator}seatext=${personalizationId}`;
};

const handleEdit = (record) => {
setCurrentKeyword(record);
setUrlInputValue(removeSeatextFromUrl(record.focusUrl));
setEditModalVisible(true);
};

const handleEditSave = (values) => {
const personalizationId = generatePersonalizationId(values.keyword);
const baseUrl = values.focusUrl;
const fullUrl = ensureSeatextInUrl(baseUrl, personalizationId);

const updatedKeyword = {
...currentKeyword,
...values,
personalizationId,
focusUrl: fullUrl
};

setKeywords(prevKeywords =>
prevKeywords.map(k => k.id === currentKeyword.id ? updatedKeyword : k)
);

setEditModalVisible(false);
editForm.resetFields();
};

const showDeleteConfirm = (keyword) => {
setKeywordToDelete(keyword);
setDeleteModalVisible(true);
};


const handleDeleteConfirm = () => {
if (keywordToDelete) {
setKeywords(keywords.filter(k => k.id !== keywordToDelete.id));
setDeleteModalVisible(false);
message.success('Keyword optimization deleted successfully');

deletePersonalizedVariants(keywordToDelete).catch(error => {
console.error('Failed to delete keyword on server:', error);
message.error('Failed to delete keyword on server. The changes may not persist.');
});
}
};

const deletePersonalizedVariants = async (keyword) => {
  try {
    const url = normalizeUrl(keyword.focusUrl || '');

    console.log('Deleting keyword data with:', {
      url: url,
      personalizationId: keyword.personalizationId,
      originalUrl: keyword.focusUrl
    });

    if (!url) {
      throw new Error('No valid URL found for deletion');
    }

    // Use the new deleteKeywordData function instead
    const result = await deleteKeywordData(
      url,
      keyword.personalizationId
    );

    console.log('Delete request result:', result);
    return result;
  } catch (error) {
    console.error('Delete keyword data error:', {
      error: error.message,
      keyword: keyword
    });
    throw new Error(`Failed to delete keyword data: ${error.message}`);
  }
};

const saveKeywordProfile = (profile) => {
if (isSaving || !profile.keyword) {
message.error('Please enter a keyword');
return;
}

setIsSaving(true);
const serverProfile = {
  profiles: [{
    "Keyword": profile.keyword,
    "Base URL": profile.autoUrl || profile.baseUrl,  // Use autoUrl as primary
    "AI Instructions": profile.personalizationData,
    "Personalization ID": profile.personalizationId
  }]
};

sendKeywordProfilesToServer(serverProfile)
.then(response => {
if (response.success) {
  message.success('Keyword optimization saved successfully');
} else {
  message.error('Failed to save keyword optimization: ' + response.error);
}
})
.catch(error => {
console.error('Failed to save keyword optimization:', error);
message.error('Failed to save keyword optimization');
})
.finally(() => {
setTimeout(() => setIsSaving(false), 6000);
});
};





const openEditor = (keyword) => {
setSelectedKeyword(keyword);
setEditorVisible(true);
setIsLoadingVariants(true);

setTimeout(() => {
setIsLoadingVariants(false);
}, 10000);
};

const handleAddKeyword = () => {
setAddKeywordModalVisible(true);
};

const handleAddKeywordSave = (values) => {
const personalizationId = generatePersonalizationId(values.keyword);
const baseUrl = removeSeatextFromUrl(values.focusUrl);
const fullUrl = ensureSeatextInUrl(baseUrl, personalizationId);

const newKeyword = {
id: Date.now(),
...values,
personalizationId,
focusUrl: fullUrl
};

setKeywords(prevKeywords => {
const existingIndex = prevKeywords.findIndex(k => k.personalizationId === personalizationId);
if (existingIndex !== -1) {
return prevKeywords.map((k, index) => index === existingIndex ? { ...k, ...newKeyword } : k);
} else {
return [...prevKeywords, newKeyword];
}
});

setAddKeywordModalVisible(false);
addForm.resetFields();
};
return (
  <div style={{ width: '100%', margin: '0 auto', padding: '0px' }}>
    <div data-seatextdont style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%' }}>
            <Card bordered={false} style={{ width: '100%', margin: '0' }}>
              <div className="ai-card-content" style={{
                marginBottom: '30px',
                marginTop: '0px',
                backgroundColor: '#f8f8f8',
                minHeight: '350px',
                padding: '0px',
                border: '0px solid #A9A9A9',
                width: '100%',
                display: 'flex'
              }}>
                <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ 
                   flex: '0 0 40%', // Changed from flex:1 and width:30%
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  
                  paddingRight: '20px' // Add some spacing between columns
                }}>

<h2 className="ai-title" style={{ 
  fontSize: '22px', 
  marginBottom: '10px', 
  marginTop: '40px',
  marginLeft: '10px',
  width: '100%' 
}}>
  Boost Google Ads Conversions by 35%.
</h2>
<h3 className="ai-subtitle" style={{
  fontSize: '18px',
  marginBottom: '30px', 
  color: '#888', 
  marginLeft: '10px',
  width: '100%' 
}}>
  Each keyword gets a real-time, customized rewrite of your landing page.
</h3>



<div style={{ 
                    
                    display: 'flex', // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                  }}>
                    <div style={{ width: '100%', aspectRatio: '16/9' }}>
                      <VideoPlayer videoFileName="https://login.seatext.com/seatextgoogleads2.mp4" />
                    </div>
                  </div>

               





                    
                  </div>
                  <div className="ai-text-content" style={{
                        flex: '0 0 60%', // Changed from flex:1
                    paddingRight: '30px',
                    marginTop: '20px'
                  }}>
                    <div className="ai-description" style={{ 
        fontSize: '16px', 
        color: 'rgba(0,0,0,0.65)',
        counterReset: 'item',  
        marginLeft: '10px',
        
      }}>
    <p>SEATEXT AI dynamically rewrites your landing pages in real-time to match each visitor's search keyword. First, you'll need to add SEATEXT as a JavaScript snippet to your website so our AI can scan and analyze your pages for optimization. Once installed, when a visitor arrives via Google Ads, SEATEXT detects it using a special tag added to your landing page URL (e.g., yourcompany.com/?seatext=keyword) and instantly updates your website text with a version optimized for that keyword. If SEATEXT ever stops working, rest assured that your visitors will continue to see the original version of your landing page without any disruptions, ensuring a smooth experience at all times.
    Once installed, follow the instructions below:</p>

<p style={{ marginTop: '15px', marginBottom: '15px' }}>
  If you need assistance, please contact our support team via chat or watch the video for a complete demonstration of the process.
</p>

<ol style={{ 
  paddingLeft: '30px', 
  marginBottom: '15px',
  marginTop: '20px',
  listStyle: 'none',
  counterReset: 'section'
}}>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">{`${1}.`}</span>
    <strong>Download Keywords & Add AI Instructions:</strong> Download keywords from Google Ads Editor - free software from Google (<a href="https://ads.google.com/intl/en_in/home/tools/ads-editor/" target="_blank" rel="noopener noreferrer">Windows</a> or <a href="https://ads.google.com/intl/en_in/home/tools/ads-editor/" target="_blank" rel="noopener noreferrer">Mac</a>).
  </li>

  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">{`${2}.`}</span>
    <strong>Upload Keywords with AI Instructions:</strong> Use the <span className="button-style"><UploadOutlined /> Upload CSV with Keywords and AI Instructions</span> button to import your CSV file. While AI instructions are completely optional - SEATEXT AI is capable of optimizing your content automatically based on keywords alone - you can provide custom instructions to fine-tune the optimization. For example, you can specify target audience preferences or add specific content rules. If you choose to use instructions, we recommend preparing them in ChatGPT first for efficiency. Click <span className="button-style">Send all keywords to server and start optimization</span> when ready and wait at least 10 minutes while AI is preparing content.
  </li>

  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">{`${3}.`}</span>
    <strong>Preview & Edit:</strong> Check the AI-generated content through the provided links under <strong>Personalized URL</strong>. Use the <span className="button-style"><RobotOutlined /></span> Edit AI Variants button to make manual edits of texts or update AI instructions for better results and push them to server again.
  </li>

  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">{`${4}.`}</span>
    <strong>Update Google Ads Campaign:</strong> Use the <span className="button-style"><DownloadOutlined /> Download CSV with Keywords and Links</span> button to get your CSV file. Import to Google Ads Editor using multiple changes mode. Accept changes and push it to Google Ads when ready.
  </li>
</ol>
      </div>

      {/* Add button styling */}
      <style>{`
        .button-style {
          display: inline-flex;
          align-items: center;
          padding: 2px 8px;
          margin: 0 4px;
          background-color: #f0f0f0;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          color: #000;
          font-size: 14px;
          cursor: pointer;
          transition: all 0.3s;
        }
        .button-style:hover {
          background-color: #1890ff;
          border-color: #1890ff;
          color: #fff;
        }
        .button-style .anticon {
          font-size: 12px;
          margin-right: 4px;
          position: relative;
          top: -1px;
        }
        .list-number {
          position: absolute;
          left: -25px;
        }
      `}</style>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>

    <div data-seatexttranslateoff data-seatextdont style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddKeyword}
          style={{ backgroundColor: '##f8f8f8', borderColor: '##f8f8f8', height: '40px', fontSize: '14px' }}
        >
          Add New Keyword
        </Button>
        <Upload
          accept=".csv"
          beforeUpload={(file) => {
            handleCSVUpload(file);
            return false;
          }}
        >
          <Button
            type="primary"
            icon={<UploadOutlined />}
            style={{ backgroundColor: '##f8f8f8', borderColor: '##f8f8f8', height: '40px', fontSize: '14px' }}
          >
            Upload CSV with Keywords and AI Instructions
          </Button>
        </Upload>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleCSVExport}
          style={{ backgroundColor: '##f8f8f8', borderColor: '##f8f8f8', height: '40px', fontSize: '14px' }}
        >
          Download CSV with Keywords and Links
        </Button>
      </div>
      <div style={{ maxWidth: '300px' }}>
        <p style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.45)', marginBottom: '0' }}>
          Download <a href="https://login.seatext.com/templateforseatext.csv">template CSV</a> to understand what format Seatext AI needs
        </p>
      </div>
    </div>

    <div data-seatextdont data-seatexttranslateoff>
      <Table
        columns={columns}
        dataSource={keywords}
        rowKey="id"
        style={{ marginTop: 20 }}
        pagination={{ pageSize: 10 }}
        scroll={{ x: true }}
        size="small"
      />
    </div>

    <Button
        type="primary"
        onClick={batchProcessKeywords}
        style={{ marginTop: 20 }}
        disabled={isProcessing}
      >
        {isProcessing ? (
          <span>
            <Spin size="small" style={{ marginRight: '8px' }} />
            Processing... {countdown}s
          </span>
        ) : (
          'Send all keywords to server and start optimization'
        )}
      </Button>

    <Modal
      title="Confirm Deletion"
      visible={deleteModalVisible}
      onOk={handleDeleteConfirm}
      onCancel={() => setDeleteModalVisible(false)}
    >
      <p>Are you sure you want to delete all AI texts for this keyword?</p>
    </Modal>

    <Modal
      title="Edit Keyword"
      visible={editModalVisible}
      onCancel={() => {
        setEditModalVisible(false);
        editForm.resetFields();
        setUrlInputValue(`https://${domain}/`);
      }}
      footer={null}
      style={modalStyle}
      width={1060}
    >
      <KeywordForm
        form={editForm}
        initialValues={{...currentKeyword, focusUrl: urlInputValue}}
        onFinish={handleEditSave}
      />
    </Modal>

    <Modal
      title="Add New Keyword"
      visible={addKeywordModalVisible}
      onCancel={() => {
        setAddKeywordModalVisible(false);
        addForm.resetFields();
      }}
      footer={null}
      style={modalStyle}
      width={1060}
    >
      <KeywordForm 
        form={addForm}
        initialValues={{
          focusUrl: `https://${domain}/`
        }}
        onFinish={handleAddKeywordSave}
      />
    </Modal>

    <PersonalizedVariantEditor
      visible={editorVisible}
      onClose={() => {
        setEditorVisible(false);
        setIsLoadingVariants(false);
      }}
      url={selectedKeyword ? selectedKeyword.autoUrl : ''}
      language="English"
      personalizationId={selectedKeyword?.personalizationId}
    >
      {isLoadingVariants && (
        <div style={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <Spin size="large" />
          <div style={{ marginTop: '20px', color: '#666' }}>
            Loading optimized variants...
          </div>
        </div>
      )}
    </PersonalizedVariantEditor>
  </div>
);
};

export default AILandingPageOptimization;