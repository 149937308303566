import React from 'react';

const YouTubeBackground = () => {
  return (
    <div className="text-background-container">
      <div className="content">
       
        <p>
          Your visitors spend 80% of their time reading text on your website, which is critical for conversions. 
          In real time, SEATEXT AI predicts and applies the best text changes for each website visitor—like 
          automatic translations or personalized messaging—to boost conversion rates by up to 35%
        </p>
      </div>
      <style jsx>{`
        .text-background-container {
          background-color:  #1A36B4; /* seatext background */
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2rem;
          color: white;
          overflow: hidden; /* Prevents scrolling */
        }
        
        .content {
          max-width: 600px;
          text-align: center;
        }
        
        h1 {
          font-size: 3rem;
          margin-bottom: 1rem;
          font-weight: bold;
        }
        
        h2 {
          font-size: 1.8rem;
          margin-bottom: 2rem;
          font-weight: 600;
        }
        
        p {
          font-size: 1.2rem;
          line-height: 1.6;
        }
      `}</style>
    </div>
  );
};


export default YouTubeBackground;