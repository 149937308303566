import React, { useState, useEffect, memo, useCallback, useMemo } from 'react';
import { Layout, Typography, Menu } from 'antd';
import { Link, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import Cookies from 'js-cookie';
import {
  ExclamationCircleOutlined, ApartmentOutlined, EditOutlined, ShopOutlined, AimOutlined, TranslationOutlined,
  UserOutlined, MobileOutlined, DownloadOutlined, FrownOutlined,
  AppstoreOutlined, BookOutlined, GlobalOutlined, ForkOutlined, LineChartOutlined, DashboardOutlined, LogoutOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

// Import your components
import SeatextdemoWordPress from './SeatextdemoWordPress';
import "./welcome.css";
import { checkAndSaveDomainStatus, fetchLanguageCodesAndVariants } from './utils';
import Seatextdemo from './seatextdemo';
import AISalesPersonalisation from './AIsalespersonalisation';
import AIMarketingPersonalisation from './AIMarketingPersonalisation';
import TranslationSettingsPage from './Settingstranslation';
import VariantsEditPage from './VariantEdit';
import PlanSelectAndPayment from './PlanSelectAndPayment';
import YoutubeVideoPage from './YoutubeVideoPage';
import FAQPage from './FAQPage';
import ImageUploader from './upload';
import VariantNext from './variantnext';
import SettingsPage2 from './SettingsPage2';
import Etrade from './etrade';
import Mobile from './mobile';
import LoginPage from './LoginPage';
import ConversionSettingsPage from './ConversionSettingspage';
import URLDataPagination from './URLGraphContainer';
import AILandingPageOptimization from './AILandingPageOptimization';
import AITrafficRouting from './AITrafficRouting';

import { deleteCookiesAndRedirect } from './utils';





const { Content, Sider } = Layout;
const { Text } = Typography;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: null,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
  path: 'https://lottie.host/529bf41e-e8d4-44dc-ad85-ac20b44fcd6a/jtT5t8jdkx.json'
};

const MemoizedSider = memo(({ children, ...props }) => (
  <Sider {...props}>
    {children}
  </Sider>
));

const getMenuItems = () => [
  

  {
    key: 'category-core',
    label: 'CORE AI FEATURES',
    type: 'category'
  },
  {
    key: 'aimanager',
    icon: <ApartmentOutlined />,
    label: 'Main AI Hub',
    description: 'Assign specific AI models to different pages',
    link: '/welcome/aimanager'
  },
  {
    key: 'variantEdit',
    icon: <EditOutlined />,
    label: 'Variant Editor - Adjust AI Text Variants',
    description: 'Edit AI variants in any language and see the current status of testing',
    link: '/welcome/variant-edit'
  },
  {
    key: 'URLDataPagination',
    icon: <LineChartOutlined />,
    label: 'Review AI Performance',
    description: 'Analyze AI performance across different pages of your website',
    link: '/welcome/URLDataPagination'
  },

  {
    key: 'category-specialized',
    label: 'AI MODELS',
    type: 'category'
  },


  {
    key: 'aiImprovesConversion',
    icon: <AimOutlined />,
    label: 'General AI Model Improves Website Sales',
    description: 'The general AI model enhances conversion rates over time on any website',
    link: '/welcome/SettingsPage2'
  },
  {
    key: 'aiProduct',
    icon: <ShopOutlined />,
    label: 'AI for Online Stores',
    description: 'The AI model refines product descriptions for maximum conversions',
    link: '/welcome/ai-product'
  },

  {
    key: 'ai-translation',
    icon: <TranslationOutlined />,
    label: 'AI Website Translation',
    description: 'Automatically translates your website into 125 languages.',
    link: '/welcome/TranslationSettingsPage'
  },
  {
    key: 'AIsales',
    icon: <UserOutlined />,
    label: 'AI Personalizes the Prospect Experience with Clay.com / CRM Data',
    description: 'Personalize your pages based on any external data source',
    link: '/welcome/AIsales'
  },
  {
    key: 'AILandingPageOptimization',
    icon: <DashboardOutlined />,
    label: 'AI Optimize Landing Pages for Google Ads Keywords',
    description: 'Achieve a 35% increase in conversion rates by rewriting your pages for each keyword',
    link: '/welcome/AILandingPageOptimization'
},
{
  key: 'AITrafficRouting',
  icon:<ApartmentOutlined />,
  label: 'AI Dynamically Rewrites Pages or Redirect based on Visitor Source',
  description: 'AI can automatically rewrite your pages based on where visitors are coming from or redirect them to the most relevant page',
  link: '/welcome/AITrafficRouting'
  },
  {
    key: 'category-setup',
    label: 'SETUP & INSTALLATION',
    type: 'category'
  },
  {
    key: 'seatext-install',
    icon: <DownloadOutlined />,
    label: 'How to Install SEATEXT on Your Website',
    description: 'Step-by-step guide to install SEATEXT on your website',
    link: '/welcome/seatextdemo'
  },
  {
    key: 'plans-usage',
    icon: <AppstoreOutlined />,
    label: 'Free & Premium plan',
    description: 'Switch to a premium plan if you need more conversions',
    link: '/welcome/plan-selection'
  },
  {
    key: 'category-settings',
    label: 'SETTINGS & SUPPORT',
    type: 'category'
  },
  {
    key: 'conversion-settings',
    icon: <LineChartOutlined />,  
    label: 'Conversion Tracking Settings',
    description: 'Configure which user interactions count as successful conversions',
    link: '/welcome/conversion-settings'
  },
  {
    key: 'documentation',
    icon: <BookOutlined />,
    label: 'Documentation',
    description: 'Access comprehensive documentation and user guides',
    link: 'https://seatext.com/documentation'
  },
  {
    key: 'feedback',
    icon: <FrownOutlined />, // Changed from emoji to Ant Design icon
    label: 'Not Happy with SEATEXT AI?',
    description: 'Let us know your concerns.',
    link: 'https://5dzsm3i795z.typeform.com/to/OFj10JqA',
    className: 'feedback-item'
  }
];

const MemoizedMenu = memo(({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const activeKey = useMemo(() => {
    const currentPath = location.pathname;
    const matchingItem = menuItems.find(item => {
      if (item.type === 'category' || item.link?.startsWith('http')) return false;
      return currentPath.endsWith(item.link);
    });
    return matchingItem?.key || '';
  }, [location.pathname, menuItems]);

  const handleMenuClick = useCallback((e) => {
    const item = menuItems.find(item => item.key === e.key);
    if (!item || item.type === 'category') return;
    
    if (item.link.startsWith('http')) {
      window.open(item.link, '_blank');
    } else {
      navigate(item.link);
    }
  }, [menuItems, navigate]);

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[activeKey]}
      onClick={handleMenuClick}
      className="custom-menu"
      data-seatextdont
      backgroundColor=" #1A36B4"
    >
      {menuItems.map(item => {
        if (item.type === 'category') {
          return (
            <div 
              key={item.key} 
              className="menu-category" 
              data-seatextdont
              style={{
                padding: '23px 24px 13px', // Increased top padding by 7px, bottom padding by 5px
                color: '#888',
                fontSize: '12px',
                fontWeight: '600',
                letterSpacing: '0.1em',
                cursor: 'default',
                userSelect: 'none',
                marginTop: '7px' // Added top margin for additional spacing
              }}
            >
              {item.label}
            </div>
          );
        }

        return (
          <Menu.Item 
            key={item.key} 
            icon={item.icon} 
            className={item.className}
            data-seatextdont
          >
            <span className="menu-item-label" data-seatextdont>
              {item.label}
            </span>
            {item.description && (
              <span className="menu-item-description" data-seatextdont>
                {item.description}
              </span>
            )}
          </Menu.Item>
        );
      })}






    </Menu>
  );
});

export default function WelcomePage() {
  const [domainName, setDomainName] = useState('');
  const [isInitializing, setIsInitializing] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [menuWidth] = useState(300);
  const [menuItems] = useState(getMenuItems());
  const [isWordPress, setIsWordPress] = useState(false);

  const [jsConnected, setJsConnected] = useState(true); // Add this line

  const location = useLocation();
  const navigate = useNavigate();

  // Initialization effect
  useEffect(() => {
    let mounted = true;

    const initializeApp = async () => {
      try {
        await checkAndSaveDomainStatus();
        const domainFromCookie = Cookies.get('domainName');
        const wordPressCookie = Cookies.get('wordpress');
        
        // Get JS connection status from storage
        const jsStatus = Cookies.get('js_connected') || 
                        localStorage.getItem('js_connected') || 
                        'false';

        if (mounted) {
          setDomainName(domainFromCookie || '');
          setIsWordPress(wordPressCookie === '1');
          setJsConnected(jsStatus === 'true'); // Set JS connection status
          setIsInitializing(false);
          
          // Route based on domain status
          if (location.pathname === '/welcome') {
            if (domainFromCookie) {
              navigate('/welcome/aimanager');
            } else if (wordPressCookie === '1') {
              navigate('/welcome/SeatextdemoWordPress');
            } else {
              navigate('/welcome/Seatextdemo');
            }
          }
        }
      } catch (error) {
        console.error('Initialization error:', error);
        if (mounted) setIsInitializing(false);
      }
    };

    initializeApp();

    // Handle iframe messages for form submissions
    const handleIframeMessage = async (event) => {
      if (event.data.type === 'FORM_SUBMISSION_SUCCESS') {
        const isDomainConnected = await checkAndSaveDomainStatus();
        if (isDomainConnected) {
          const newDomain = Cookies.get('domainName') || localStorage.getItem('domainName');
          setDomainName(newDomain);
          
          const fetchUrl = `${newDomain} + Whole website`.replace(/\s+/g, ' ');
   
          
          navigate("/welcome/aimanager");
        }
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      mounted = false;
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [navigate, location.pathname]);

  // Domain data loading effect
  useEffect(() => {
    let mounted = true;

    const loadDomainData = async () => {
      if (!domainName) return;

      setIsDataLoading(true);
      const fetchUrl = `${domainName} Whole website`;

      try {
        const data = await fetchLanguageCodesAndVariants(fetchUrl);
        
        if (mounted) {
          
          
          // Route to AI manager if at root path and haven't navigated yet
          const hasInitialNavigated = sessionStorage.getItem('hasInitialNavigated');
          if (!hasInitialNavigated && location.pathname === '/welcome') {
            sessionStorage.setItem('hasInitialNavigated', 'true');
            navigate('/welcome/aimanager');
          }
        }
      } catch (error) {
        console.error('Failed to load domain data:', error);
      } finally {
        if (mounted) setIsDataLoading(false);
      }
    };

    loadDomainData();

    return () => {
      mounted = false;
    };
  }, [domainName, location.pathname, navigate]);

  if (isInitializing) {
    return <div>Loading...</div>;
  }

  return (
    <div data-seatextdont>
      <Layout style={{ minHeight: '100vh' }} data-seatextdont key="main-layout">
        <MemoizedSider
          className="custom-sider"
          width={menuWidth}
          style={{ maxWidth: '400px' }}
          data-seatextdont
        >
          <div className="menu-container" data-seatextdont>
            <div className="logo-container" data-seatextdont>
              <div className="lottie-logo" data-seatextdont>
                <Lottie options={defaultOptions} />
              </div>
            </div>
            <div style={{ height: '20px' }} data-seatextdont></div>
            <div className="client-info" data-seatextdont>
              <div className="domain-name" data-seatextdont>
                <GlobalOutlined />
                <span data-seatextdont>
                {domainName ? (
    <>
      {domainName}
   
      <span 
        onClick={deleteCookiesAndRedirect}
        style={{
          color: '#007bff',
          cursor: 'pointer',
          textDecoration: 'underline',
          fontSize: '12px',
          marginLeft: '8px',
          display: 'inline-flex',
          alignItems: 'center'
        }}
      >
        <LogoutOutlined style={{ marginRight: '4px' }} />
        Logout
      </span>

      {/* Temporarily commented out JS connection warning
      {!jsConnected && (
        <div
          style={{
            color: 'red',
            padding: '12px',
            margin: '10px 0',
            backgroundColor: '#ffe6e6',
            borderRadius: '4px',
            fontSize: '13px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <ExclamationCircleOutlined
            style={{
              fontSize: '16px',
              color: 'red',
              flexShrink: 0
            }}
          />
          <span>
            JS script is not installed on your website properly.
            If you just installed it - please visit your website several times
            till this error message disappears. If it still doesn't disappear
            after 30 minutes - please contact our support.
          </span>
        </div>
      )} */}
    </>
  ) : isWordPress ? (
    'Follow instructions to activate AI on your website.'
  ) : (
    'DEMO account. Install on your website to start.'
  )}
</span>
          </div>
          
            </div>
            <MemoizedMenu menuItems={menuItems} />
          </div>
        </MemoizedSider>
        <Layout className="site-layout" data-seatextdont>
          <Content className="site-content" data-seatextdont>
            {isDataLoading && <div>.</div>}
            <div className="content-container" data-seatextdont>
              <Routes>
                <Route path="/" element={
                  domainName ? (
                    <Navigate replace to="/welcome/aimanager" />
                  ) : isWordPress ? (
                    <Navigate replace to="/welcome/SeatextdemoWordPress" />
                  ) : (
                    <Navigate replace to="/welcome/Seatextdemo" />
                  )
                } />
                <Route path="plan-selection" element={<PlanSelectAndPayment />} />
                <Route path="variant-edit" element={<VariantsEditPage />} />
                <Route path="learn" element={<YoutubeVideoPage />} />
                <Route path="faq" element={<FAQPage />} />
                <Route path="TranslationSettingsPage" element={<TranslationSettingsPage />} />
                <Route path="SettingsPage2" element={<SettingsPage2 />} />
                <Route path="aimanager" element={<VariantNext />} />
                <Route path="AIsales" element={<AISalesPersonalisation />} />
                <Route path="ai-mobile" element={<Mobile />} />
                <Route path="ai-product" element={<Etrade />} />
                <Route path="image-upload" element={<ImageUploader />} />
                <Route path="Seatextdemo" element={<Seatextdemo />} />
                <Route path="AIMarketingPersonalisation" element={<AIMarketingPersonalisation />} />
                <Route path="SeatextdemoWordPress" element={<SeatextdemoWordPress />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="conversion-settings" element={<ConversionSettingsPage />} />
                <Route path="URLDataPagination" element={<URLDataPagination />} />
                <Route path="AILandingPageOptimization" element={<AILandingPageOptimization />} />
                <Route path="AITrafficRouting" element={<AITrafficRouting />} />
              </Routes>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}