import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalScript from './GlobalScript'; // Ensure the path is correct
import { ConfigProvider } from 'antd';
//import '@carbon/charts/styles.css';
//import 'carbon-components/css/carbon-components.min.css'; // Carbon styles
///import "@carbon/react/scss/globals/scss/styles";

// Function to change favicon
const changeFavicon = (link) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
        favicon.href = link;
    } else {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = link;
        document.head.appendChild(newFavicon);
    }
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalScript /> {/* Including the GlobalScript component */}
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

document.title = "Seatext AI";
changeFavicon('https://login.seatext.com/favicon.ico'); // call the function with the path to your favicon

