import React, { useState, useEffect } from 'react';
import { Typography, Card, Button, Input, Form, Table, Switch, Modal, Upload, message, Spin, Tooltip } from 'antd';
import { PlusOutlined, InfoCircleOutlined, EditOutlined, DeleteOutlined, UploadOutlined, DownloadOutlined, RobotOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { sendRoutesToServer, fetchRoutesFromServer, deletePersonalisedVariantsForUrl } from './utils'; 
import VideoPlayer from './VideoPlayer';
import PersonalizedVariantEditor from './PersonalizedVariantEditor';

const { Title } = Typography;

// Function for normalizing URLs
const normalizeUrl = (url) => {
  if (!url) return '';
  let normalizedUrl = url.trim();
  if (!normalizedUrl.match(/^https?:\/\//i)) {
    normalizedUrl = `https://${normalizedUrl}`;
  }
  return normalizedUrl.replace(/\/+$/, '');
};

// Function to validate a URL
const isValidUrl = (url) => {
  try {
    new URL(url.startsWith('http') ? url : 'https://' + url);
    return true;
  } catch (error) {
    return false;
  }
};

// Helper function to get the hostname with error handling
const getHostname = (url) => {
  try {
    return new URL(url).hostname;
  } catch (error) {
    return url;
  }
};

function AITrafficRouting() {
    const [routes, setRoutes] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [currentRoute, setCurrentRoute] = useState(null);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [processing, setProcessing] = useState(false);
    const [countdown, setCountdown] = useState(0);
    
    // New state variables for AI variant editor
    const [editorVisible, setEditorVisible] = useState(false);
    const [selectedProspect, setSelectedProspect] = useState(null);
    const [isLoadingVariants, setIsLoadingVariants] = useState(false);
  
    useEffect(() => {
      async function loadRoutes() {
        const routesArray = await fetchRoutesFromServer();
        console.log("Fetched routesArray:", routesArray);
        // Map each route using the server's capitalized property names.
        const mappedRoutes = routesArray.map(item => ({
          id: item.IDOfAITexts,  // WAS: item.ID - THIS IS THE CRITICAL FIX
          sourceUrl: item.Referrer,
          targetUrl: item.FullURL,
          redirectUrl: item.RedirectingURL,
          aiPrompt: item.ClientAIInstructions,
          enableRewrite: item.AllowAIToRewriteText,
          enableRedirect: item.AllowRedirect
        }));
        console.log("Mapped Routes:", mappedRoutes);
        setRoutes(mappedRoutes);
      }
      loadRoutes();
    }, []); // Empty dependency array to load routes only once on mount
  




    
  // Function to open the AI variant editor
  const openEditor = (route) => {
    setSelectedProspect({
      focusUrl: route.targetUrl,
      personalizationId: route.id
    });
    setEditorVisible(true);
    setIsLoadingVariants(true);
    // Simulate loading time (remove this in production and use real loading state)
    setTimeout(() => {
      setIsLoadingVariants(false);
    }, 1000);
  };

  // Function to delete personalized variants (left for reference but not being used now)
  const deletePersonalizedVariants = async (route) => {
    try {
      // Extract and normalize the URL from the route's targetUrl
      const url = normalizeUrl(route.targetUrl || '');
      console.log('Deleting variants with data:', { 
        url: url, 
        personalizationId: route.id, 
        originalUrl: route.targetUrl 
      });
      
      if (!url) {
        throw new Error('No valid URL found for deletion');
      }
      
      const result = await deletePersonalisedVariantsForUrl(
        url,
        'English',
        route.id
      );
      
      console.log('Delete request result:', result);
      return result;
    } catch (error) {
      console.error('Delete variants error:', { error: error.message, route: route });
      throw new Error(`Failed to delete AI texts: ${error.message}`);
    }
  };

  // Definition of table columns.
  // Column order:
  // 1. Source URL – where the visitor came from;
  // 2. Target URL – the primary landing page;
  // 3. AI Rewrite Instructions – instructions for AI to adapt the landing page;
  // 4. Rewrite Active – toggle to enable AI rewriting;
  // 5. Redirect URL – optional URL for redirection;
  // 6. Redirect Active – toggle to enable redirection;
  // 7. Actions – edit/delete rule.
  const columns = [
    {
      title: 'Source URL',
      dataIndex: 'sourceUrl',
      key: 'sourceUrl',
      render: (text) => {
        const hostname = getHostname(text);
        const normalizedUrl = normalizeUrl(text);
        
        return (
          <Tooltip title={text}>
            <a 
              href={normalizedUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click event from triggering
              }}
            >
              {text.slice(0, 40)}{text.length > 40 && '...'}
            </a>
          </Tooltip>
        );
      }
    },
    {
      title: 'Target URL',
      dataIndex: 'targetUrl',
      key: 'targetUrl',
      render: (text, record) => {
        const hostname = getHostname(text);
        // Create a complete URL with the referrer parameter
        const fullUrl = `${normalizeUrl(text)}?ref=${encodeURIComponent(record.sourceUrl)}`;
        
        // Add target="_blank" to open in a new window and rel="noopener noreferrer" for security
        return <a 
          href={fullUrl} 
          target="_blank" 
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click event from triggering
          }}
        >
          {hostname}
        </a>;
      }
    },
   
    {
      title: 'AI Rewrite Instructions',
      dataIndex: 'aiPrompt',
      key: 'aiPrompt',
      // Customize the entire cell
      onCell: (record) => ({
        onClick: () => {
          setCurrentRoute(record);
          setEditModalVisible(true);
          form.setFieldsValue(record);
        },
        style: { 
          cursor: 'pointer',
          padding: '8px',  // Standard padding
          height: '100%'
        }
      }),
      render: (text) => (
        <div>
          {text ? (
            <div>
              {text}
            </div>
          ) : (
            <div style={{ color: '#bbb', fontStyle: 'italic', fontSize: '12px' }}>No instructions added</div>
          )}
        </div>
      )
    },
    {
      title: 'AI Rewrite Active',
      key: 'enableRewrite',
      render: (_, record) => (
        <Tooltip title="Enable AI rewriting">
          <Switch 
            checked={record.enableRewrite}
            onChange={checked => handleToggle(record.id, 'enableRewrite', checked)}
          />
        </Tooltip>
      )
    },
    {
      title: 'Redirect URL',
      dataIndex: 'redirectUrl',
      key: 'redirectUrl',
      // Customize the entire cell
      onCell: (record) => ({
        onClick: () => {
          setCurrentRoute(record);
          setEditModalVisible(true);
          form.setFieldsValue(record);
        },
        style: { 
          cursor: 'pointer',
          padding: '8px',  // Standard padding
          height: '100%'
        }
      }),
      render: (text) => text ? (
        <a 
          onClick={(e) => e.stopPropagation()} 
          href={text} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {(() => {
            try {
              return new URL(text).hostname;
            } catch (err) {
              return text;
            }
          })()}
        </a>
      ) : (
        <div style={{ color: '#bbb', fontStyle: 'italic', fontSize: '12px' }}>No redirect URL</div>
      )
    },
    {
      title: 'Redirect Active',
      key: 'enableRedirect',
      render: (_, record) => (
        <Tooltip title="Enable redirection">
          <Switch
            checked={record.enableRedirect}
            onChange={checked => handleToggle(record.id, 'enableRedirect', checked)}
          />
        </Tooltip>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Tooltip title="Edit AI-generated text variants">
            <Button 
              icon={<RobotOutlined />} 
              onClick={() => openEditor(record)}
            />
          </Tooltip>
        </div>
      )
    }
  ];


  const tableStyles = `
  .ant-table-cell:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
  // CSV file import handling.
  // Expected CSV fields: sourceUrl, targetUrl, redirectUrl, aiPrompt, enableRewrite, enableRedirect.
  const handleCSVUpload = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const newRoutes = results.data.map(row => ({
          // SERVER WILL GENERATE ID - DON'T ACCEPT CLIENT IDs
          sourceUrl: normalizeUrl(row.sourceUrl),
          targetUrl: normalizeUrl(row.targetUrl),
          redirectUrl: row.redirectUrl ? normalizeUrl(row.redirectUrl) : '',
          aiPrompt: row.aiPrompt || '',
          enableRewrite: row.enableRewrite === 'true',
          enableRedirect: row.enableRedirect === 'true'
        }));
        setRoutes(prev => [...prev, ...newRoutes]);
        message.success(`Imported ${newRoutes.length} rules`);
      }
    });
  };
  

  // Export rules to a CSV file.
  const handleCSVExport = () => {
    const csvContent = [
      ['IDOfAITexts', 'Source URL', 'Target URL', 'Redirect URL', 'AI Rewrite Instructions', 'Enable Rewrite', 'Enable Redirect'],
      ...routes.map(r => [
        r.id, 
        r.sourceUrl,
        
        r.targetUrl,
        r.redirectUrl,
        r.aiPrompt,
        r.enableRewrite,
        r.enableRedirect ? 'true' : 'false'
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'content-adaptation-rules.csv';
    link.click();
  };

  // Save and deploy all rules to the server.
  const handleSave = async () => {
    setProcessing(true);
    setCountdown(60);
    try {
      await sendRoutesToServer(routes);
      message.success('Rules saved and deployed successfully');
    } catch (error) {
      message.error('Error saving rules: ' + error.message);
    } finally {
      setTimeout(() => {
        setProcessing(false);
        setCountdown(0);
      }, 60000);
    }
  };

  // Toggle a boolean value in the rule.
  const handleToggle = (id, field, value) => {
    // id here should already be IDOfAITexts after the initial fix
    setRoutes(prev => prev.map(r => 
      r.id === id ? { ...r, [field]: value } : r
    ));
  };

  const handleEdit = (rule) => {
    setCurrentRoute(rule);
    setEditModalVisible(true);
    form.setFieldsValue(rule);
  };

  const handleDelete = (id) => {
    // id here should be IDOfAITexts
    setRoutes(prev => prev.filter(r => r.id !== id));
  };

  // Handle form submission with URL normalization.
  const handleFormSubmit = (values) => {
    // Only trim the values instead of normalizing them.
    const updatedValues = {
      ...values,
      sourceUrl: values.sourceUrl.trim(),
      targetUrl: values.targetUrl.trim(),
      redirectUrl: values.redirectUrl ? values.redirectUrl.trim() : ''
    };
  
    if (currentRoute) {
      setRoutes(prev => prev.map(r =>
        r.id === currentRoute.id ? { ...updatedValues, id: currentRoute.id } : r
      ));
    } else {
      setRoutes(prev => [...prev, updatedValues]);
    }
  
    setEditModalVisible(false);
    setAddModalVisible(false);
    form.resetFields();
  };
  

  return (
    <div style={{ width: '100%', margin: '0 auto', padding: '0px' }}>
      {/* Product Description Block */}
      <div data-seatextdont style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%' }}>
              <Card bordered={false} style={{ width: '100%', margin: '0' }}>
                <div className="ai-card-content" style={{
                  marginBottom: '30px',
                  marginTop: '0px',
                  backgroundColor: '#f8f8f8',
                  minHeight: '350px',
                  padding: '0px',
                  border: '0px solid #A9A9A9',
                  width: '100%',
                  display: 'flex'
                }}>
                  <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ 
                      flex: '0 0 40%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '20px'
                    }}>
                      <h2 className="ai-title" style={{ 
                        fontSize: '22px', 
                        marginBottom: '10px', 
                        marginTop: '40px',
                        marginLeft: '10px',
                        width: '100%' 
                      }}>
                       AI dynamically rewrites pages based on visitor origin
                      </h2>
                      <h3 className="ai-subtitle" style={{
                        fontSize: '18px',
                        marginBottom: '30px', 
                        color: '#888', 
                        marginLeft: '10px',
                        width: '100%' 
                      }}>
                        Dynamically rewrite pages or redirect visitors based on their referrer.
                      </h3>
                      <div style={{ 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        <div style={{ width: '100%', aspectRatio: '16/9' }}>
                          <VideoPlayer videoFileName="https://login.seatext.com/SEATEXT-AI-ROUTING.mp4" />
                        </div>
                      </div>
                    </div>
                    <div className="ai-text-content" style={{
                      flex: '0 0 60%',
                      paddingRight: '30px',
                      marginTop: '20px'
                    }}>
<div className="ai-description" style={{ 
  fontSize: '16px', 
  color: 'rgba(0,0,0,0.65)',
  marginLeft: '10px',
}}>
<p>
  Our AI analyzes your visitors' traffic sources - whether they come from product articles or category reviews - and optimizes your landing pages accordingly. The AI scans the referring article, reads it thoroughly, and analyzes why customers clicked on your link from that source. This deep understanding enables the AI to dynamically rewrite content to better match the referring article's theme.
</p>
<p style={{ marginTop: '15px' }}>
  You can use redirection to send visitors coming from specific articles directly to the most relevant product page or to a search results page. When both redirection and AI rewriting are enabled, Seatext will first redirect visitors to the specified page and then dynamically adapt its content based on the referring article. This provides the most personalized experience as visitors not only land on the most relevant page but also see content tailored to their interests.
</p>
<p style={{ marginTop: '15px', marginBottom: '15px' }}>
  Getting started is easy. Follow these steps:
</p>
<ol style={{ 
  paddingLeft: '30px', 
  marginBottom: '15px',
  marginTop: '20px',
  listStyle: 'none',
  counterReset: 'section'
}}>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">1.</span>
    <strong>Edit your traffic routing rules:</strong> Click directly on any field in the table to modify it. Use the <span className="button-style-icon"><EditOutlined /></span> icon to change multiple fields at once, or click directly on AI Rewrite Instructions or Redirect URL to edit those specific values.
  </li>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">2.</span>
    <strong>Customize AI instructions:</strong> You can give the AI direct instructions (like "change this specific text to this") or general guidance (like "rewrite this content for insurance products" or "emphasize the waterproof features"). The more specific your instructions, the better the AI can match your expectations.
  </li>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">3.</span>
    <strong>For bulk editing:</strong> Use <span className="button-style"><DownloadOutlined /> Export CSV</span> to download your current rules, make changes, then <span className="button-style"><UploadOutlined /> Import CSV</span> to upload your modified file.
  </li>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">4.</span>
    <strong>Push new rules to server:</strong> Click <span className="button-style">Deploy Rules</span> and Seatext will scan incoming articles and update content based on your new instructions.
  </li>
  <li style={{ marginBottom: '8px', position: 'relative', counterIncrement: 'section' }}>
    <span className="list-number">5.</span>
    <strong>Customize content versions:</strong> Click the <span className="button-style-icon"><RobotOutlined /></span> icon to review and customize AI-generated content variants. Click on Target URL to see how visitors from different sources will view your website.
  </li>
</ol>
<style>{`
  .button-style {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    margin: 0 4px;
    background-color: #f0f0f0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: #000;
    font-size: 14px;
    cursor: pointer;
    vertical-align: middle;
    line-height: 1.5;
  }
  .button-style-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    margin: 0 2px;
    background-color: #f0f0f0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: #000;
    font-size: 14px;
    vertical-align: middle;
    line-height: 1;
  }
  .list-number {
    position: absolute;
    left: -25px;
  }
`}</style>
</div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* Content Adaptation Manager */}
      <div style={{ maxWidth: "100%", margin: '0 auto', padding: 24 }}>
        <div style={{ marginBottom: 24, display: 'flex', gap: 16 }}>
     
          <Upload
            accept=".csv"
            beforeUpload={file => {
              handleCSVUpload(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Import CSV</Button>
          </Upload>
          <Button icon={<DownloadOutlined />} onClick={handleCSVExport}>
            Export CSV
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={processing}
            style={{ marginLeft: 'auto' }}
          >
            {processing ? (
              <Spin size="small" />
            ) : `Deploy Rules${countdown > 0 ? ` (${countdown}s)` : ''}`}
          </Button>
        </div>

        <Table
  columns={columns}
  dataSource={routes}
  rowKey="id"
  bordered
  pagination={{ pageSize: 10 }}
  scroll={{ x: true }}
  onRow={(record) => ({
    onClick: () => {
      // Don't do anything here, let the cell click handlers work
    },
    // Add special styling to make cells clickable
    style: { 
      cursor: 'pointer'
    }
  })}
  components={{
    body: {
      // Override the cell rendering completely
      cell: (props) => {
        const { children, ...restProps } = props;
        
        // Check if this is an editable cell (our two specific columns)
        const isEditableCell = 
          (restProps.key && restProps.key.includes('aiPrompt')) || 
          (restProps.key && restProps.key.includes('redirectUrl'));
        
        if (isEditableCell) {
          return (
            <td {...restProps} style={{ padding: 0 }}>
              <div style={{ 
                padding: '8px',
                minHeight: '42px',
                position: 'relative',
                height: '100%',
                width: '100%'
              }}>
                {children}
                <EditOutlined 
                  style={{ 
                    position: 'absolute', 
                    top: '8px', 
                    right: '8px',
                    fontSize: '12px',
                    opacity: 0.6
                  }} 
                />
              </div>
            </td>
          );
        }
        
        return <td {...restProps}>{children}</td>;
      }
    }
  }}
/>

        <Modal
          title={currentRoute ? "Edit Content Adaptation Rule" : "New Content Adaptation Rule"}
          visible={editModalVisible || addModalVisible}
          onCancel={() => {
            setEditModalVisible(false);
            setAddModalVisible(false);
            form.resetFields();
          }}
          footer={null}
          width={800}
        >
          <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item
              name="sourceUrl"
              label="Source URL"
              rules={[
                { required: true, message: 'Please enter a Source URL' },
                { validator: (_, v) => isValidUrl(v) ? Promise.resolve() : Promise.reject('Invalid URL') }
              ]}
            >
              <Input placeholder="https://article-site.com/path" />
            </Form.Item>

            <Form.Item
              name="targetUrl"
              label="Target URL"
              rules={[
                { required: true, message: 'Please enter a Target URL' },
                { validator: (_, v) => isValidUrl(v) ? Promise.resolve() : Promise.reject('Invalid URL') }
              ]}
            >
              <Input placeholder="https://yourstore.com/main-page" />
            </Form.Item>

            <Form.Item
              name="aiPrompt"
              label="AI Rewrite Instructions"
              tooltip="How should the landing page be adapted based on the article content?"
            >
              <Input.TextArea
                rows={4}
                placeholder="For example: Emphasize product benefits or highlight seasonal promotions..."
                maxLength={1000}
              />
            </Form.Item>

            <Form.Item
              name="enableRewrite"
              label="Enable AI Rewriting"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              name="redirectUrl"
              label="Redirect URL (optional)"
              rules={[{ validator: (_, v) => !v || isValidUrl(v) ? Promise.resolve() : Promise.reject('Invalid URL') }]}
            >
              <Input placeholder="https://yourstore.com/search?query=funny+tshirts" />
            </Form.Item>

            <Form.Item
              name="enableRedirect"
              label="Enable Redirection"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              {currentRoute ? 'Save Changes' : 'Add Rule'}
            </Button>
          </Form>
        </Modal>

        {/* AI Variant Editor Modal */}
        <PersonalizedVariantEditor
          visible={editorVisible}
          onClose={() => {
            setEditorVisible(false);
            setIsLoadingVariants(false); // Reset loading state when closing
          }}
          url={selectedProspect ? selectedProspect.focusUrl.split('?')[0] : ''}
          language="English"
          personalizationId={selectedProspect?.personalizationId}
        >
          {isLoadingVariants && (
            <div style={{ 
              position: 'absolute', 
              top: 0, 
              left: 0, 
              right: 0, 
              bottom: 0, 
              background: 'rgba(255, 255, 255, 0.8)', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              zIndex: 1000 
            }}>
              <Spin size="large" />
              <div style={{ marginTop: '20px', color: '#666' }}>
                Loading personalized variants...
              </div>
            </div>
          )}
        </PersonalizedVariantEditor>
      </div>
    </div>
  );
}

export default AITrafficRouting;